<template>
  <div>

    <svg
      v-if="scope === 'arredo3'"
      id="log"
      :class="scope"
      data-name="logo"
      width="250"
      height="250"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 595.28 595.28"
    ><rect
      width="595.28"
      height="595.28"
      style="fill:#00754a"
    /><path
      d="M26.19,357.17l40-118.73H90.12l39.57,118.73H105.53l-8.41-27.48H58.38l-8.73,27.48Zm38.15-47H91.19L77.94,267.72Z"
      style="fill:#fff"
    /><path
      d="M133.67,357.17v-86h20v16.08s7.48-18.31,24.29-18.31a15,15,0,0,1,5,.84v22a34.44,34.44,0,0,0-7.56-.87c-4.13,0-20.72,1-20.72,25.71v40.55Z"
      style="fill:#fff"
    /><path
      d="M188.17,357.17v-86h20v16.08s7.48-18.31,24.29-18.31a15,15,0,0,1,5,.84v22a34.44,34.44,0,0,0-7.56-.87c-4.13,0-20.72,1-20.72,25.71v40.55Z"
      style="fill:#fff"
    /><path
      d="M236.69,314.94c.06-12.86,5.48-45.94,39.39-46,30.75.31,37.84,31.73,36.88,51.06-7.29.08-55.21,0-55.21,0s-1.13,21.81,18.69,21.81A17.52,17.52,0,0,0,293,330.56h18.62s-5.31,28.82-35.37,28.82S236.69,334.38,236.69,314.94Zm20.93-10.06h34.19s-.64-18.17-16.95-18.17C258.92,286.79,257.62,304.88,257.62,304.88Z"
      style="fill:#fff"
    /><path
      d="M375.92,281.58V238.42h21.25V357.17H377.06V346.25s-6.25,13.19-23.5,13.19-34-14.13-34.56-45.28c0-31.28,17.08-45.22,34.33-45.22C369.67,268.94,375.92,281.58,375.92,281.58ZM358.5,341.67c14,0,18.25-14.17,18.25-27.51s-4.17-27.41-18-27.41-18.5,14.08-18.5,27.41C340.25,325.75,344.5,341.67,358.5,341.67Z"
      style="fill:#fff"
    /><path
      d="M446.92,268.75c18.08,0,40,11.79,40,45.46s-21.42,45.23-40,45.23-39.42-12.52-39.42-45.23S429,268.75,446.92,268.75Zm-18.25,45.46c0,21.71,10.08,27.46,18.52,27.46s18.56-4.84,18.56-27.58-11.25-27.34-18.56-27.34C438.88,286.75,428.67,292.5,428.67,314.21Z"
      style="fill:#fff"
    /><path
      d="M515.62,271.17H495.38s3.54-32.75,35.12-32.75c23.92,0,34.83,17.58,34.83,32.75,0,19.95-14.45,23.52-14.45,23.52s18.37,2.73,18.37,28.06-21.83,36.69-38.58,36.69c-18.84,0-38.42-11.61-38.42-39.52h20.17S513.83,340,530.75,340c11.94,0,17.25-10.21,16.5-19.75,0-13.58-11.29-16.37-23.92-15.67V287.81s22,3.19,22-14.64c0-11.79-9.17-15.25-14.63-15.25C521.33,257.92,516.58,265.58,515.62,271.17Z"
      style="fill:#fff"
    /><path
      d="M148,455.92h4.58s1.46-10.3-8.71-10.3c-9.54,0-9.45,12.21-9.45,15.92s-1,16.13,9.45,16.13S152.61,466,152.61,466h-4.49s.6,8-4.21,8-4.79-6.93-4.79-12.26,0-12.27,4.69-12.27S148,455.92,148,455.92Z"
      style="fill:#fff"
    /><path
      d="M274,455.92h4.58s1.46-10.3-8.71-10.3c-9.54,0-9.46,12.21-9.46,15.92s-.95,16.13,9.46,16.13S278.61,466,278.61,466h-4.49s.6,8-4.21,8-4.79-6.93-4.79-12.26,0-12.27,4.69-12.27S274,455.92,274,455.92Z"
      style="fill:#fff"
    /><path
      d="M214.89,469V446.17h-4.62V469s.31,4.9-4.1,4.9S202,469,202,469V446.17h-4.62V469s-.52,8.72,8.75,8.72S214.89,469,214.89,469Z"
      style="fill:#fff"
    /><rect
      x="323.46"
      y="446.17"
      width="4.58"
      height="30.79"
      style="fill:#fff"
    /><polygon
      points="374.38 476.96 374.38 446.17 379.96 446.17 388.29 469.04 388.29 446.17 392.67 446.17 392.67 476.96 387.04 476.96 378.75 453.58 378.75 476.96 374.38 476.96"
      style="fill:#fff"
    /><polygon
      points="439.17 476.96 439.17 446.17 454.67 446.17 454.67 450.5 443.79 450.5 443.79 458.92 454.04 458.92 454.04 463.13 443.79 463.13 443.79 472.88 455 472.88 455 476.96 439.17 476.96"
      style="fill:#fff"
    /><path
      d="M295.27,150.47a28.4,28.4,0,1,1-28.39-28.39h28.39Z"
      style="fill:#fff"
    /><path
      d="M329.31,122.08a28.39,28.39,0,1,1-28.39,28.39V122.08Z"
      style="fill:#fff"
    /><path
      d="M300.92,90.88a25.56,25.56,0,1,1,25.55,25.55H300.92Z"
      style="fill:#fff"
    /></svg>

    <svg
      v-if="scope === 'prima'"
      id="Livello_2"
      :class="scope"
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="74"
      viewBox="0 0 561.94 229.57"
    ><g id="Livello_1-2"><path d="M105.97,90.84h-12.97v18.46h12.97c6.29,0,10.28-3.69,10.28-9.18s-3.99-9.28-10.28-9.28m.7,30.3h-13.67v25.37h-13.87V79.1h27.54c14.67,0,23.45,9.56,23.45,21.02s-8.78,21.02-23.45,21.02" /><path d="M203.04,90.84h-13.07v17.8h13.07c6.09,0,10.08-3.69,10.08-8.9s-3.99-8.9-10.08-8.9m10.78,55.67l-13.87-26.89h-9.98v26.89h-13.87V79.1h27.84c14.47,0,23.05,9.37,23.05,20.64,0,9.47-6.09,15.34-12.97,17.7l15.86,29.07h-16.06Z" /><rect
      x="274.39"
      y="70.28"
      width="13.16"
      height="85.04"
    /><polygon points="379.86 146.51 379.86 107.22 366.99 132.78 358.09 132.78 345.12 107.22 345.12 146.51 331.96 146.51 331.96 79.1 344.93 79.1 362.54 115.55 380.05 79.1 393.02 79.1 393.02 146.51 379.86 146.51" /><path d="M453.37,98.98l-8.52,24.52h16.76l-8.24-24.52Zm15.72,47.53l-3.98-11.93h-23.95l-4.07,11.93h-13.73l24.52-67.41h10.32l24.62,67.41h-13.73Z" /><polygon points="0 0 0 225.61 125.12 225.61 125.12 211.43 14.17 211.43 14.17 14.17 547.77 14.17 547.77 211.43 436.83 211.43 436.83 225.61 561.94 225.61 561.94 0 0 0" /><path d="M184.17,229.57c-2.81,0-5.11-.91-6.91-2.53-2.59-2.33-2.52-5.22-2.52-9.2s-.07-6.87,2.52-9.2c1.8-1.62,4.1-2.53,6.91-2.53,4.79,0,8.53,2.46,9.43,7.29h-5.08c-.5-1.85-1.8-3.27-4.32-3.27-1.37,0-2.48,.45-3.2,1.2-.97,.97-1.26,2.07-1.26,6.51s.29,5.54,1.26,6.51c.72,.74,1.84,1.2,3.2,1.2,2.52,0,3.82-1.42,4.32-3.27h5.08c-.9,4.83-4.68,7.29-9.43,7.29" /><path d="M225.11,229.57c-5.26,0-9.43-3.21-9.43-8.1v-15.16h5v15c0,2.66,1.73,4.24,4.43,4.24s4.46-1.59,4.46-4.24v-15h4.97v15.16c0,4.89-4.18,8.1-9.43,8.1" /><path d="M266.84,229.57c-2.81,0-5.11-.91-6.91-2.53-2.59-2.33-2.52-5.22-2.52-9.2s-.07-6.87,2.52-9.2c1.8-1.62,4.1-2.53,6.91-2.53,4.79,0,8.53,2.46,9.43,7.29h-5.08c-.5-1.85-1.8-3.27-4.32-3.27-1.37,0-2.48,.45-3.2,1.2-.97,.97-1.26,2.07-1.26,6.51s.29,5.54,1.26,6.51c.72,.74,1.84,1.2,3.2,1.2,2.52,0,3.82-1.42,4.32-3.27h5.08c-.9,4.83-4.68,7.29-9.43,7.29" /><rect
      x="298.74"
      y="206.31"
      width="5"
      height="23.06"
    /><polygon points="343.03 229.37 332.88 215.22 332.88 229.37 327.87 229.37 327.87 206.31 332.34 206.31 342.49 220.43 342.49 206.31 347.49 206.31 347.49 229.37 343.03 229.37" /><polygon points="371.62 229.37 371.62 206.31 388.5 206.31 388.5 210.32 376.62 210.32 376.62 215.73 386.74 215.73 386.74 219.75 376.62 219.75 376.62 225.35 388.5 225.35 388.5 229.37 371.62 229.37" /></g></svg>
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  data() {
    return {
      scope: store.state.scope.appScope,
    }
  },
}

</script>
