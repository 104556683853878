<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <custom-logo />
        <h2 class="brand-text text-primary ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >

        <b-overlay
          :show="showOverlay"
          variant="white"
          class="w-100"
        >

          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Benvenuto nell'app Autisti {{ company_name }}
            </b-card-title>
            <b-card-text class="mb-2">
              Accedi inserendo le tue credenziali.
            </b-card-text>

            <b-alert
              v-if="loginError"
              show
              fade
              class="mb-0"
              variant="danger"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <span>{{ loginError }}</span>
              </div>
            </b-alert>

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Username"
                  label-for="user"
                  class="mb-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="User"
                    vid="user"
                    rules="required"
                  >
                    <b-form-input
                      id="user"
                      v-model="user"
                      :state="errors.length > 0 ? false:null"
                      name="user"
                      placeholder="User Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group
                  label="Password"
                  label-for="login-password"
                >
                  <!--
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>Hai dimenticato la password?</small>
                    </b-link>
                  </div>
                  -->
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <!--
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Ricordami
                  </b-form-checkbox>
                </b-form-group>
                -->

                <!-- submit buttons -->
                <div class="mt-4">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    ACCEDI
                  </b-button>
                </div>
              </b-form>
            </validation-observer>

            <!--
            <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>
            -->
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import appConfig from '../../../config.json'

import CustomLogo from '@/custom/logo/Logo.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    heightFade,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    CustomLogo,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      user: '',
      sideImg: require('@/assets/images/pages/driver-login-ok.svg'),
      showOverlay: false,
      company_name: appConfig[store.state.scope.appScope].company_name,

      loginError: '',

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
    console.log('================= login scope =================')
    console.log(store.state.scope.appScope)
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.showOverlay = true
          // https://laravel.com/docs/9.x/sanctum#csrf-protection
          this.$http.get('/sanctum/csrf-cookie').then(response => {
            this.$http.post('/login', {
              username: this.user,
              password: this.password,
              service_id: appConfig[store.state.scope.appScope].service_id,
              // eslint-disable-next-line no-shadow
            }).then(response => {
              const userData = response.data.data.result.profilo
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('userToken', response.data.data.result.token)
              store.commit('vehicle/REMOVE_VEHICLE')
              this.$root.$data.user_logged = true
              this.$root.$data.user_active_datetime = new Date()

              // this.$ability.update(userData.ability)
              this.showOverlay = false

              this.$router.replace(getHomeRouteForLoggedInUser()).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Benvenuto ${userData.nome || userData.cognome}`,
                    icon: 'LogInIcon',
                    variant: 'success',
                    text: `Accesso effettuato come ${userData.c_utente}. Buon lavoro!`,
                  },
                })
              })
            })
              .catch(error => {
                this.showOverlay = false

                if (error.response) {
                  if (error.response.data.statusCode === 'USER_NOT_FOUND' || error.response.data.statusCode === 'INVALID_PASSWORD' || error.response.data.message === 'USER_NOT_FOUND') {
                    this.loginError = 'Credenziali non valide!'
                  } else if (error.response.data.message) {
                    this.loginError = error.response.data.message
                  } else {
                    this.loginError = 'Error'
                  }
                }
              })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
